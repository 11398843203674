// ../../../react-shim.js
import React from "react";

// src/editable.tsx
import { cx, runIfFn } from "@chakra-ui/shared-utils";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";

// src/editable-context.ts
import { createContext } from "@chakra-ui/react-context";
var [EditableStylesProvider, useEditableStyles] = createContext({
  name: `EditableStylesContext`,
  errorMessage: `useEditableStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Editable />" `
});
var [EditableProvider, useEditableContext] = createContext({
  name: "EditableContext",
  errorMessage: "useEditableContext: context is undefined. Seems you forgot to wrap the editable components in `<Editable />`"
});

// src/use-editable.ts
import { useFocusOnPointerDown } from "@chakra-ui/react-use-focus-on-pointer-down";
import { useSafeLayoutEffect } from "@chakra-ui/react-use-safe-layout-effect";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { useCallbackRef } from "@chakra-ui/react-use-callback-ref";
import { ariaAttr, callAllHandlers } from "@chakra-ui/shared-utils";
import { useCallback, useEffect, useRef, useState } from "react";
function contains(parent, child) {
  if (!parent)
    return false;
  return parent === child || parent.contains(child);
}
function useEditable(props = {}) {
  const {
    onChange: onChangeProp,
    onCancel: onCancelProp,
    onSubmit: onSubmitProp,
    value: valueProp,
    isDisabled,
    defaultValue,
    startWithEditView,
    isPreviewFocusable = true,
    submitOnBlur = true,
    selectAllOnFocus = true,
    placeholder,
    onEdit: onEditCallback,
    ...htmlProps
  } = props;
  const onEditProp = useCallbackRef(onEditCallback);
  const defaultIsEditing = Boolean(startWithEditView && !isDisabled);
  const [isEditing, setIsEditing] = useState(defaultIsEditing);
  const [value, setValue] = useControllableState({
    defaultValue: defaultValue || "",
    value: valueProp,
    onChange: onChangeProp
  });
  const [prevValue, setPrevValue] = useState(value);
  const inputRef = useRef(null);
  const previewRef = useRef(null);
  const editButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  useFocusOnPointerDown({
    ref: inputRef,
    enabled: isEditing,
    elements: [cancelButtonRef, submitButtonRef]
  });
  const isInteractive = !isEditing && !isDisabled;
  useSafeLayoutEffect(() => {
    var _a, _b;
    if (isEditing) {
      (_a = inputRef.current) == null ? void 0 : _a.focus();
      if (selectAllOnFocus)
        (_b = inputRef.current) == null ? void 0 : _b.select();
    }
  }, []);
  useUpdateEffect(() => {
    var _a, _b, _c;
    if (!isEditing) {
      (_a = editButtonRef.current) == null ? void 0 : _a.focus();
      return;
    }
    (_b = inputRef.current) == null ? void 0 : _b.focus();
    if (selectAllOnFocus) {
      (_c = inputRef.current) == null ? void 0 : _c.select();
    }
    onEditProp == null ? void 0 : onEditProp();
  }, [isEditing, onEditProp, selectAllOnFocus]);
  const onEdit = useCallback(() => {
    if (isInteractive) {
      setIsEditing(true);
    }
  }, [isInteractive]);
  const onUpdatePrevValue = useCallback(() => {
    setPrevValue(value);
  }, [value]);
  const onCancel = useCallback(() => {
    setIsEditing(false);
    setValue(prevValue);
    onCancelProp == null ? void 0 : onCancelProp(prevValue);
  }, [onCancelProp, setValue, prevValue]);
  const onSubmit = useCallback(() => {
    setIsEditing(false);
    setPrevValue(value);
    onSubmitProp == null ? void 0 : onSubmitProp(value);
  }, [value, onSubmitProp]);
  useEffect(() => {
    if (isEditing)
      return;
    const inputEl = inputRef.current;
    if ((inputEl == null ? void 0 : inputEl.ownerDocument.activeElement) === inputEl) {
      inputEl == null ? void 0 : inputEl.blur();
    }
  }, [isEditing]);
  const onChange = useCallback((event) => {
    setValue(event.currentTarget.value);
  }, [setValue]);
  const onKeyDown = useCallback((event) => {
    const eventKey = event.key;
    const keyMap = {
      Escape: onCancel,
      Enter: (event2) => {
        if (!event2.shiftKey && !event2.metaKey) {
          onSubmit();
        }
      }
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      action(event);
    }
  }, [onCancel, onSubmit]);
  const onKeyDownWithoutSubmit = useCallback((event) => {
    const eventKey = event.key;
    const keyMap = {
      Escape: onCancel
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      action(event);
    }
  }, [onCancel]);
  const isValueEmpty = value.length === 0;
  const onBlur = useCallback((event) => {
    if (!isEditing)
      return;
    const doc = event.currentTarget.ownerDocument;
    const relatedTarget = event.relatedTarget ?? doc.activeElement;
    const targetIsCancel = contains(cancelButtonRef.current, relatedTarget);
    const targetIsSubmit = contains(submitButtonRef.current, relatedTarget);
    const isValidBlur = !targetIsCancel && !targetIsSubmit;
    if (isValidBlur) {
      if (submitOnBlur) {
        onSubmit();
      } else {
        onCancel();
      }
    }
  }, [submitOnBlur, onSubmit, onCancel, isEditing]);
  const getPreviewProps = useCallback((props2 = {}, ref = null) => {
    const tabIndex = isInteractive && isPreviewFocusable ? 0 : void 0;
    return {
      ...props2,
      ref: mergeRefs(ref, previewRef),
      children: isValueEmpty ? placeholder : value,
      hidden: isEditing,
      "aria-disabled": ariaAttr(isDisabled),
      tabIndex,
      onFocus: callAllHandlers(props2.onFocus, onEdit, onUpdatePrevValue)
    };
  }, [
    isDisabled,
    isEditing,
    isInteractive,
    isPreviewFocusable,
    isValueEmpty,
    onEdit,
    onUpdatePrevValue,
    placeholder,
    value
  ]);
  const getInputProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    hidden: !isEditing,
    placeholder,
    ref: mergeRefs(ref, inputRef),
    disabled: isDisabled,
    "aria-disabled": ariaAttr(isDisabled),
    value,
    onBlur: callAllHandlers(props2.onBlur, onBlur),
    onChange: callAllHandlers(props2.onChange, onChange),
    onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
    onFocus: callAllHandlers(props2.onFocus, onUpdatePrevValue)
  }), [
    isDisabled,
    isEditing,
    onBlur,
    onChange,
    onKeyDown,
    onUpdatePrevValue,
    placeholder,
    value
  ]);
  const getTextareaProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    hidden: !isEditing,
    placeholder,
    ref: mergeRefs(ref, inputRef),
    disabled: isDisabled,
    "aria-disabled": ariaAttr(isDisabled),
    value,
    onBlur: callAllHandlers(props2.onBlur, onBlur),
    onChange: callAllHandlers(props2.onChange, onChange),
    onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDownWithoutSubmit),
    onFocus: callAllHandlers(props2.onFocus, onUpdatePrevValue)
  }), [
    isDisabled,
    isEditing,
    onBlur,
    onChange,
    onKeyDownWithoutSubmit,
    onUpdatePrevValue,
    placeholder,
    value
  ]);
  const getEditButtonProps = useCallback((props2 = {}, ref = null) => ({
    "aria-label": "Edit",
    ...props2,
    type: "button",
    onClick: callAllHandlers(props2.onClick, onEdit),
    ref: mergeRefs(ref, editButtonRef),
    disabled: isDisabled
  }), [onEdit, isDisabled]);
  const getSubmitButtonProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    "aria-label": "Submit",
    ref: mergeRefs(submitButtonRef, ref),
    type: "button",
    onClick: callAllHandlers(props2.onClick, onSubmit),
    disabled: isDisabled
  }), [onSubmit, isDisabled]);
  const getCancelButtonProps = useCallback((props2 = {}, ref = null) => ({
    "aria-label": "Cancel",
    id: "cancel",
    ...props2,
    ref: mergeRefs(cancelButtonRef, ref),
    type: "button",
    onClick: callAllHandlers(props2.onClick, onCancel),
    disabled: isDisabled
  }), [onCancel, isDisabled]);
  return {
    isEditing,
    isDisabled,
    isValueEmpty,
    value,
    onEdit,
    onCancel,
    onSubmit,
    getPreviewProps,
    getInputProps,
    getTextareaProps,
    getEditButtonProps,
    getSubmitButtonProps,
    getCancelButtonProps,
    htmlProps
  };
}

// src/editable.tsx
var Editable = forwardRef(function Editable2(props, ref) {
  const styles = useMultiStyleConfig("Editable", props);
  const ownProps = omitThemingProps(props);
  const { htmlProps, ...context } = useEditable(ownProps);
  const { isEditing, onSubmit, onCancel, onEdit } = context;
  const _className = cx("chakra-editable", props.className);
  const children = runIfFn(props.children, {
    isEditing,
    onSubmit,
    onCancel,
    onEdit
  });
  return /* @__PURE__ */ React.createElement(EditableProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(EditableStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ref,
    ...htmlProps,
    className: _className
  }, children)));
});
Editable.displayName = "Editable";

// src/editable-input.tsx
import { chakra as chakra2, forwardRef as forwardRef2 } from "@chakra-ui/system";
import { cx as cx2 } from "@chakra-ui/shared-utils";

// src/shared.ts
var commonStyles = {
  fontSize: "inherit",
  fontWeight: "inherit",
  textAlign: "inherit",
  bg: "transparent"
};

// src/editable-input.tsx
var EditableInput = forwardRef2(function EditableInput2(props, ref) {
  const { getInputProps } = useEditableContext();
  const styles = useEditableStyles();
  const inputProps = getInputProps(props, ref);
  const _className = cx2("chakra-editable__input", props.className);
  return /* @__PURE__ */ React.createElement(chakra2.input, {
    ...inputProps,
    __css: {
      outline: 0,
      ...commonStyles,
      ...styles.input
    },
    className: _className
  });
});
EditableInput.displayName = "EditableInput";

// src/editable-textarea.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
import { cx as cx3 } from "@chakra-ui/shared-utils";
var EditableTextarea = forwardRef3(function EditableTextarea2(props, ref) {
  const { getTextareaProps } = useEditableContext();
  const styles = useEditableStyles();
  const textareaProps = getTextareaProps(props, ref);
  const _className = cx3("chakra-editable__textarea", props.className);
  return /* @__PURE__ */ React.createElement(chakra3.textarea, {
    ...textareaProps,
    __css: {
      outline: 0,
      ...commonStyles,
      ...styles.textarea
    },
    className: _className
  });
});
EditableTextarea.displayName = "EditableTextarea";

// src/editable-preview.tsx
import { chakra as chakra4, forwardRef as forwardRef4 } from "@chakra-ui/system";
import { cx as cx4 } from "@chakra-ui/shared-utils";
var EditablePreview = forwardRef4(function EditablePreview2(props, ref) {
  const { getPreviewProps } = useEditableContext();
  const styles = useEditableStyles();
  const previewProps = getPreviewProps(props, ref);
  const _className = cx4("chakra-editable__preview", props.className);
  return /* @__PURE__ */ React.createElement(chakra4.span, {
    ...previewProps,
    __css: {
      cursor: "text",
      display: "inline-block",
      ...commonStyles,
      ...styles.preview
    },
    className: _className
  });
});
EditablePreview.displayName = "EditablePreview";

// src/use-editable-controls.tsx
function useEditableControls() {
  const {
    isEditing,
    getEditButtonProps,
    getCancelButtonProps,
    getSubmitButtonProps
  } = useEditableContext();
  return {
    isEditing,
    getEditButtonProps,
    getCancelButtonProps,
    getSubmitButtonProps
  };
}

// src/use-editable-state.tsx
function useEditableState() {
  const { isEditing, onSubmit, onCancel, onEdit, isDisabled } = useEditableContext();
  return {
    isEditing,
    onSubmit,
    onCancel,
    onEdit,
    isDisabled
  };
}
export {
  Editable,
  EditableInput,
  EditablePreview,
  EditableTextarea,
  useEditable,
  useEditableControls,
  useEditableState,
  useEditableStyles
};
